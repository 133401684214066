// src/pages/Departures.js
import React from 'react';
import './Departures.css';

const Departures = () => {
    // Dummy data for employees who have left the company
    const departedEmployees = [
        { id: 1, name: 'Louis', date: '31-07-2024' },
        { id: 2, name: 'Mark', date: '?' },
        { id: 3, name: 'Pier Luigi', date: '?' },
        { id: 4, name: 'Arthur', date: '?'},
        { id: 5, name: 'Wan Hee', date: '?' },
        { id: 6, name: 'Azzedine', date: '?' },
        { id: 7, name: 'Conner', date: '?' },
        { id: 8, name: 'Remi', date: '?' },
        { id: 9, name: 'Olga', date: '?' },
        { id: 10, name: 'Chelsie', date: '?' },
        { id: 11, name: 'Lawrence', date: '?' },
        { id: 12, name: 'Paulina', date: '?' },
    ];

    return (
        <div className="departures-container">
            <h1>Departures</h1>
            <table className="departure-board">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Employee Name</th>
                    </tr>
                </thead>
                <tbody>
                    {departedEmployees.map(employee => (
                        <tr key={employee.id}>
                            <td>{employee.date}</td>
                            <td>{employee.name}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Departures;
