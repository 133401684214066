import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <nav className="navbar">
                <h2 className="navbar-brand">Spherea</h2>
                <div className="burger-icon" onClick={toggleNavbar}>
                    &#9776;
                </div>
            </nav>
            <div className={`side-navbar ${isOpen ? 'open' : ''}`}>
                <ul className="side-navbar-links">
                    <li><Link to="/" onClick={toggleNavbar}>Employee Turnover Calculator</Link></li>
                    <li><Link to="/countdown" onClick={toggleNavbar}>Countdown</Link></li>
                    <li><Link to="/departures" onClick={toggleNavbar}>Departures</Link></li>
                </ul>
            </div>
            {isOpen && <div className="overlay" onClick={toggleNavbar}></div>}
        </>
    );
};

export default Navbar;
