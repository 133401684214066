import React, { useState, useEffect } from 'react';
import './Countdown.css';

const Countdown = () => {
    const [countdown, setCountdown] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const fivePM = new Date(now.toDateString() + ' 17:00:00');

            if (now <= fivePM) {
                const timeDifference = fivePM - now;
                const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
                const seconds = Math.floor((timeDifference / 1000) % 60);

                setCountdown(`${hours}h ${minutes}m ${seconds}s`);
            } else {
                setCountdown('🎊 It is past 5 PM! 🎊');
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="timer-container">
            <h1>Time Until 5 PM</h1>
            {countdown && (
                <div className="countdown">
                    <h3>{countdown}</h3>
                </div>
            )}
        </div>
    );
};

export default Countdown;
