import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import EmployeeTurnoverCalculator from './pages/EmployeeTurnoverCalculator/EmployeeTurnoverCalculator';
import Countdown from './pages/Countdown/Countdown';
import Departures from './pages/Departures/Departures';


function App() {
    return (
        <Router>
            <div className="App">
                <Navbar />
                <div className="content">
                    <Routes>
                        <Route path="/" element={<EmployeeTurnoverCalculator />} />
                        <Route path="/countdown" element={<Countdown />} />
                        <Route path="/departures" element={<Departures />} />
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
