import React, { useState } from 'react';
import './EmployeeTurnoverCalculator.css';

const EmployeeTurnoverCalculator = () => {
    const [startEmployees, setStartEmployees] = useState('');
    const [endEmployees, setEndEmployees] = useState('');
    const [employeesLeft, setEmployeesLeft] = useState('');
    const [turnoverRate, setTurnoverRate] = useState(null);

    const calculateTurnoverRate = () => {
        if (startEmployees && endEmployees && employeesLeft) {
            const averageEmployees = (parseInt(startEmployees) + parseInt(endEmployees)) / 2;
            const rate = (parseInt(employeesLeft) / averageEmployees) * 100;
            setTurnoverRate(rate.toFixed(2));  // Round to two decimal places
        } else {
            setTurnoverRate(null);
        }
    };

    return (
        <div className="calculator-container">
            <h1>Employee Turnover Calculator</h1>
            <div className="input-group">
                <label>Employees at Start of Period:</label>
                <input 
                    type="number" 
                    value={startEmployees} 
                    onChange={(e) => setStartEmployees(e.target.value)} 
                    placeholder="Enter number"
                />
            </div>
            <div className="input-group">
                <label>Employees at End of Period:</label>
                <input 
                    type="number" 
                    value={endEmployees} 
                    onChange={(e) => setEndEmployees(e.target.value)} 
                    placeholder="Enter number"
                />
            </div>
            <div className="input-group">
                <label>Employees Who Left:</label>
                <input 
                    type="number" 
                    value={employeesLeft} 
                    onChange={(e) => setEmployeesLeft(e.target.value)} 
                    placeholder="Enter number"
                />
            </div>
            <button onClick={calculateTurnoverRate}>Calculate Turnover Rate</button>
            {turnoverRate !== null && (
                <div className="result">
                    <h3>Turnover Rate: {turnoverRate}%</h3>
                </div>
            )}
        </div>
    );
};

export default EmployeeTurnoverCalculator;
